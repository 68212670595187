import React, { useEffect, useRef } from 'react';

const AlertMessage = ({ show, setShow, type, title, description, button_text, button_link }) => {

    useEffect(() => {
        // Automatically hide the alert after 15 seconds
        if (show) {

            const timer = setTimeout(() => {
                setTimeout(() => {
                    setShow(false)
                }, 300); // Allow fade-out animation to complete
            }, 8000); // Message stays visible for 8 seconds

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [show]);

    const handleClose = () => {
        setTimeout(() => {
            setShow(false);
        }, 300); // Allow fade-out animation to complete
    };
    if(title && title == 'Invalid Values'){
        description = 'Please check if you have entered correct values';
    }
    return (
        <div className={`custom-alert-message ${type} ${show ? 'd-flex' : 'd-none'}`}>
            <div className='d-flex align-items-start gap-2'>
                <div className="alert-icon">
                    {type === 'success' &&
                        <>
                            {/* Success icon */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                <circle cx="14.5" cy="14.5" r="14.5" fill="#14B15C" />
                                <path d="M23 9L12 20L7 15" stroke="#2E2E2E" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </>}
                    {type === 'error' &&
                        <>
                            {/* Error icon */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                <circle cx="14.5" cy="14.5" r="14.5" fill="#FF5353" />
                                <path d="M19.6181 9.38281L9.38281 19.6181M9.38281 9.38281L19.6181 19.6181" stroke="#2E2E2E" strokeWidth="3.41176" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </>}
                    {type === 'warning' &&
                        <>
                            {/* Warning icon */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                <circle cx="14.5" cy="14.5" r="14.5" fill="#FFB800" />
                                <g clipPath="url(#clip0_2718_2337)">
                                    <path d="M13.4316 19.0918C13.2949 17.9069 13.1628 16.8997 13.0352 16.0703C12.9076 15.2318 12.7845 14.5072 12.666 13.8965C12.5566 13.2858 12.4518 12.7617 12.3516 12.3242C12.2513 11.8867 12.1647 11.4766 12.0918 11.0938C12.028 10.7018 11.9733 10.3053 11.9277 9.9043C11.8913 9.50326 11.873 9.03385 11.873 8.49609C11.873 8.01302 11.9368 7.58008 12.0645 7.19727C12.1921 6.81445 12.3607 6.49089 12.5703 6.22656C12.7891 5.95312 13.0306 5.74805 13.2949 5.61133C13.5684 5.46549 13.8418 5.39258 14.1152 5.39258C14.3978 5.39258 14.6712 5.46549 14.9355 5.61133C15.209 5.74805 15.4505 5.95312 15.6602 6.22656C15.8698 6.49089 16.0384 6.81445 16.166 7.19727C16.2936 7.58008 16.3574 8.01302 16.3574 8.49609C16.3574 9.03385 16.3346 9.50326 16.2891 9.9043C16.2526 10.3053 16.1979 10.7018 16.125 11.0938C16.0612 11.4766 15.9792 11.8867 15.8789 12.3242C15.7878 12.7617 15.6829 13.2858 15.5645 13.8965C15.4551 14.5072 15.3366 15.2318 15.209 16.0703C15.0814 16.8997 14.9538 17.9069 14.8262 19.0918H13.4316ZM12.2422 22.4141C12.2422 22.6784 12.2878 22.9245 12.3789 23.1523C12.4792 23.3802 12.6159 23.5762 12.7891 23.7402C12.9622 23.9043 13.1582 24.0319 13.377 24.123C13.6048 24.2233 13.8509 24.2734 14.1152 24.2734C14.3796 24.2734 14.6257 24.2233 14.8535 24.123C15.0905 24.0319 15.291 23.9043 15.4551 23.7402C15.6283 23.5762 15.765 23.3802 15.8652 23.1523C15.9655 22.9245 16.0156 22.6784 16.0156 22.4141C16.0156 22.1589 15.9655 21.9173 15.8652 21.6895C15.765 21.4525 15.6283 21.252 15.4551 21.0879C15.291 20.9147 15.0905 20.778 14.8535 20.6777C14.6257 20.5775 14.3796 20.5273 14.1152 20.5273C13.8509 20.5273 13.6048 20.5775 13.377 20.6777C13.1582 20.778 12.9622 20.9147 12.7891 21.0879C12.6159 21.252 12.4792 21.4525 12.3789 21.6895C12.2878 21.9173 12.2422 22.1589 12.2422 22.4141Z" fill="#2E2E2E" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2718_2337">
                                        <rect width="8" height="19" fill="white" transform="translate(10 5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </>}
                </div>
                <div className="alert-content">
                    {title && <span className='heading'>{title}</span>}
                    {description && <span className='description'>{description}</span>}
                    {type === 'error' &&
                        <span className="sub-description">If you don’t know how to fix this, get help</span>
                    }
                    {type === 'warning' &&
                        <span className="sub-description">You can safely ignore this warning, contact Zaap team if you're unsure of what to do</span>
                    }
                    {(type !== 'success' || (type === 'success' && button_text)) &&
                        <div className="alert-actions">
                            {type === 'error' &&
                                <a href='https://zaap.ai/support#tutorials' className="alert-btn secondary-btn">Read Docs</a>
                            }
                            {type !== 'success' &&
                                <a href='https://zaap.ai/support' className="alert-btn primary-btn">Contact Zaap Team</a>
                            }
                            {button_text &&
                                <a href={button_link} className="alert-btn primary-btn">
                                    {button_text}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                        <path d="M1 7L7 1M7 1H3M7 1V5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </a>

                            }
                        </div>
                    }
                </div>
            </div>
            <button className="alert-close-btn" onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path d="M15.25 1.75L1.75 15.25M1.75 1.75L15.25 15.25" stroke="#AEAEAE" stroke-width="3.41176" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        </div>
    );
};

export default AlertMessage;
